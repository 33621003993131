<template>
    <div class="list">
        <div class="listtab">
            <div class="listtab-back" @touchend="handleBack">
                <van-icon name="arrow-left" size="0.36rem" />
            </div>
            <van-search shape="round" v-model="page.params.name" placeholder="贷款产品" :show-action="page.params.name!=''"
                @search="onSearch" @cancel="onCancel" />
        </div>
        <div class="list-tab">
            <van-dropdown-menu active-color="#FF9D20">
                <van-dropdown-item title="综合排序" v-model="page.params.complexType" :options="complexTypeList"
                    @change="handleChange($event,'complexType')" />
                <van-dropdown-item title="额度" v-model="page.params.limitType" :options="limitTypeList"
                    @change="handleChange($event,'limitType')" />
                <van-dropdown-item title="期限" v-model="page.params.termType" :options="termTypeList"
                    @change="handleChange($event,'termType')" />
            </van-dropdown-menu>
        </div>
        <div class="listtitle">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list v-model="loading" :error.sync="error" :immediate-check="immediatecheck" :finished="finished"
                    error-text="请求失败，点击重新加载" finished-text="没有更多了" @load="onLoad">
                    <hot-list :hotList="listData"></hot-list>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { HANDLEAPI } from '@/api/api' // api

import Vue from 'vue'
import { Search } from 'vant'

import HotList from '@/components/Index/hotlist.vue'

Vue.use(Search) // list列表块
export default {
  name: 'list',
  components: { HotList },
  data () {
    return {
      loading: false,
      immediatecheck: false,
      finished: false,
      error: false,
      isLoading: false,
      listData: [],
      totalPage: 0,
      page: {
        currentPage: 1,
        pageSize: 10,
        params: {
          complexType: '',
          limitType: '',
          termType: '',
          name: '',
          scene: '2'
        }
      },
      complexTypeList: [
        { value: '', text: '不限' },
        {
          value: '1',
          text: '高通过率'
        },
        {
          value: '2',
          text: '额度最大'
        },
        {
          value: '3',
          text: '速度最快'
        },
        {
          value: '4',
          text: '利率最低'
        }
      ],
      limitTypeList: [
        { value: '', text: '不限' },
        {
          value: '1',
          text: '1000~2000元'
        },
        {
          value: '2',
          text: '2000~5000元'
        },
        {
          value: '3',
          text: '5000~50000元'
        },
        {
          value: '4',
          text: '50000~200000元'
        }
      ],
      termTypeList: [
        { value: '', text: '不限' },
        {
          value: '1',
          text: '1~3个月'
        },
        {
          value: '2',
          text: '3~6个月'
        },
        {
          value: '3',
          text: '6~12个月'
        },
        {
          value: '4',
          text: '12月以上'
        }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  activated () {
    if (this.$route.query.id) {
      this.page.params.complexType = ''
      this.page.params.limitType = ''
      this.page.params.termType = ''
      this.page.currentPage = 1
      this.page.params.complexType = this.$route.query.id
      this.getList()
    }
  },
  methods: {
    // 搜索
    onSearch (val) {
      this.page.params.complexType = ''
      this.page.params.limitType = ''
      this.page.params.termType = ''
      this.page.currentPage = 1
      this.getList()
    },
    // 下拉刷新
    onRefresh () {
      this.page.currentPage = 1
      this.loading = false
      this.finished = false
      this.getList()
    },
    // 加载更多
    onLoad () {
      this.page.currentPage++
      if (this.totalPage < this.page.currentPage) {
        this.loading = false
        this.finished = true
        return
      }
      this.loading = true
      this.finished = false
      this.getList('add')
    },
    // 取消搜索
    onCancel () {
      this.page.currentPage = 1
      this.getList(1)
    },
    // 获取数据源
    getList (type) {
      this.$store.commit('showLoading')
      HANDLEAPI('surplusesList', this.page)
        .then((res) => {
          this.loading = false
          this.isLoading = false
          this.immediatecheck = true
          this.$store.commit('hideLoading')
          if (res.code === 0) {
            if (type === 'add') {
              this.listData = this.listData.concat(res.data.list)
            } else {
              this.listData = res.data.list
            }
            this.totalPage = res.data.lastPage
          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true
          this.loading = false
          this.isLoading = false
          this.$store.commit('hideLoading')
        })
    },
    handleChange (val, type) {
      this.page.params.complexType = ''
      this.page.params.limitType = ''
      this.page.params.termType = ''
      this.page.params.name = ''
      switch (type) {
        case 'complexType':
          this.page.params.complexType = val
          break
        case 'limitType':
          this.page.params.limitType = val
          break
        case 'termType':
          this.page.params.termType = val
          break
      }
      this.page.currentPage = 1
      this.getList()
    },
    handleBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.list {
    .listtab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.3rem;
        background: #fff;
        .listtab-back {
            width: 10%;
            i {
                font-size: 0.36rem;
            }
        }
        .van-search {
            width: 90%;
            .van-search__content {
                /deep/.van-cell {
                    .van-field__left-icon {
                        .van-icon-search {
                            font-size: 0.3rem;
                            color: #bbbcbd;
                        }
                    }
                    .van-cell__value {
                        .van-field__body {
                            input {
                                font-size: 0.28rem;
                            }
                            /* WebKit browsers */
                            input::-webkit-input-placeholder {
                                color: #bbbcbd;
                                font-size: 0.28rem;
                            }
                            /* Mozilla Firefox 4 to 18 */
                            input:-moz-placeholder {
                                color: #bbbcbd;
                                font-size: 0.28rem;
                            }
                            /* Mozilla Firefox 19+ */
                            input::-moz-placeholder {
                                color: #bbbcbd;
                                font-size: 0.28rem;
                            }
                            /* Internet Explorer 10+ */
                            input:-ms-input-placeholder {
                                color: #bbbcbd;
                                font-size: 0.28rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .list-tab {
        .van-dropdown-menu {
            border-top: 0.01rem solid #e1e1e1;
            border-bottom: 0.01rem solid #e1e1e1;
            /deep/.van-dropdown-menu__bar {
                box-shadow: none;
                .van-dropdown-menu__item {
                    border-right: 0.01rem solid #e4e4e4;
                    margin: 0.1rem 0;
                }
                .van-dropdown-menu__item:last-child {
                    border: none;
                }
            }
            /deep/.van-dropdown-item {
                margin-top: 1px;
            }
        }
    }
    .listtitle {
        height: calc(100vh - 1.87rem);
        overflow-y: auto;
        box-sizing: border-box;
    }
    .listtitle::-webkit-scrollbar {
        display: none;
    }
}
</style>
